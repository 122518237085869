/* File: frontend/src/pages/SuperAdmin/components/PitchAccessManager/PitchAccessManager.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  width: 100%;
  max-width: 100%;
}

.title {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-lg) 0;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-lg);
  flex-wrap: wrap;
  gap: var(--spacing-md);
}

.deckSelector {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  min-width: 200px;
  max-width: 300px;
}

.label {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.select {
  padding: var(--spacing-sm) var(--spacing-md);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  /* width: 100%; */
}

.searchContainer {
  position: relative;
  width: 300px;
  max-width: 100%;
}

.searchInput {
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-sm) var(--spacing-sm) var(--spacing-xl);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  background-color: var(--bg-primary);
}

.searchIcon {
  position: absolute;
  left: var(--spacing-sm);
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-tertiary);
}

.error, .success {
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.error {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.success {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.sectionTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.addAccessSection {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
  width: 100%;
}

/* Form row layout for better organization */
.formRow {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
  width: 100%;
  margin-bottom: var(--spacing-md);
}

.formRow .formGroup {
  flex: 1;
  min-width: 250px;
}

.buttonGroup {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.input {
  padding: var(--spacing-sm) var(--spacing-md);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  background-color: var(--bg-primary);
  width: 100%;
}

.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
  height: 38px;
  width: 100%;
  min-width: 150px;
}

.addButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.addButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Access list section with proper overflow handling */
.accessListSection {
  width: 100%;
}

/* Table container with proper scrolling */
.tableContainer {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
}

/* Table with consistent column widths */
.accessTable {
  width: 100%;
  min-width: 1000px;
  border-collapse: collapse;
}

.accessTable th,
.accessTable td {
  padding: var(--spacing-sm) var(--spacing-md);
  text-align: left;
  border-bottom: 1px solid var(--color-border-light);
}

.accessTable th {
  background-color: var(--bg-secondary);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  white-space: nowrap;
  position: sticky;
  top: 0;
}

.accessTable tr:last-child td {
  border-bottom: none;
}

.accessTable tr:hover td {
  background-color: var(--bg-hover);
}

/* Column widths - simpler approach */
.accessTable th:nth-child(1), /* Email */
.accessTable td:nth-child(1) {
  min-width: 180px;
  max-width: 200px;
}

.accessTable th:nth-child(2), /* Name */
.accessTable td:nth-child(2) {
  min-width: 120px;
  max-width: 150px;
}

.accessTable th:nth-child(3), /* Enterprise */
.accessTable td:nth-child(3) {
  min-width: 150px;
  max-width: 180px;
}

.accessTable th:nth-child(4), /* Domain */
.accessTable td:nth-child(4) {
  min-width: 120px;
  max-width: 150px;
}

.accessTable th:nth-child(5), /* Lead Potential */
.accessTable td:nth-child(5) {
  min-width: 100px;
  max-width: 120px;
}

.accessTable th:nth-child(6), /* Status */
.accessTable td:nth-child(6) {
  min-width: 80px;
  max-width: 100px;
}

.accessTable th:nth-child(7), /* Granted */
.accessTable td:nth-child(7),
.accessTable th:nth-child(8), /* Expires */
.accessTable td:nth-child(8) {
  min-width: 100px;
  max-width: 120px;
}

.accessTable th:nth-child(9), /* Views */
.accessTable td:nth-child(9) {
  min-width: 60px;
  max-width: 80px;
}

.accessTable th:nth-child(10), /* Pitch Link */
.accessTable td:nth-child(10) {
  min-width: 100px;
  max-width: 120px;
}

.accessTable th:nth-child(11), /* Actions */
.accessTable td:nth-child(11) {
  min-width: 100px;
  max-width: 120px;
}

/* Text overflow handling */
.accessTable td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.statusBadge {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.statusActive {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.statusExpired {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.statusRevoked {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.actions {
  display: flex;
  gap: var(--spacing-xs);
}

.revokeButton,
.extendButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  cursor: pointer;
  transition: all var(--transition-fast);
  border: none;
}

.revokeButton {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.revokeButton:hover:not(:disabled) {
  background-color: var(--color-error);
  color: white;
}

.extendButton {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.extendButton:hover:not(:disabled) {
  background-color: var(--color-warning);
  color: white;
}

.revokeButton:disabled,
.extendButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.pitchLink {
  color: var(--color-primary);
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.pitchLink:hover {
  text-decoration: underline;
}

.copyButton {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  border: none;
  width: 28px;
  height: 28px;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyButton:hover:not(:disabled) {
  background-color: var(--color-primary-light);
  color: var(--color-primary);
}

.copyButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  gap: var(--spacing-md);
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  color: var(--text-secondary);
  text-align: center;
  gap: var(--spacing-md);
}

.emptyState i {
  font-size: var(--font-size-3xl);
  opacity: 0.5;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .controls {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .deckSelector, .searchContainer {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .formRow {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .formRow .formGroup {
    width: 100%;
    min-width: 100%;
  }
  
  .buttonGroup {
    margin-top: var(--spacing-sm);
  }
  
  .addButton {
    width: 100%;
    max-width: 100%;
  }
  
  .searchContainer {
    width: 100%;
  }
  
  .addAccessSection, .accessListSection {
    padding: var(--spacing-md);
  }
}

@media (max-width: 480px) {
  .accessTable th,
  .accessTable td {
    padding: var(--spacing-xs) var(--spacing-sm);
    font-size: var(--font-size-xs);
  }
  
  .statusBadge {
    padding: 2px 4px;
    font-size: 10px;
  }
  
  .actions {
    flex-direction: column;
    gap: 4px;
  }
  
  .title {
    font-size: var(--font-size-xl);
  }
  
  .sectionTitle {
    font-size: var(--font-size-md);
  }
}