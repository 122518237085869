/* File: frontend/src/pages/InvestorPitch/components/slides/TractionSlide/TractionSlide.module.css */

/* Updated TractionSlide.module.css with map styling */

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-md);
  width: 100%;
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  background: linear-gradient(135deg, var(--color-green-310), var(--color-primary-500));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 800px;
  margin: 0 auto;
}

.content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto auto auto;
  gap: var(--spacing-lg);
  flex: 1;
}

/* Metrics section */
.metricsContainer {
  grid-column: 1;
  grid-row: 1;
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-md);
}

.metricCard {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  border: 1px solid var(--color-gray-1100);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.metricCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-color: var(--color-primary-500);
}

.metricIcon {
  font-size: 2rem;
  color: var(--color-primary-500);
  background: var(--color-gray-1100);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.metricContent {
  display: flex;
  flex-direction: column;
}

.metricValue {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin: 0;
}

.metricLabel {
  font-size: var(--font-size-md);
  color: var(--color-gray-300);
  margin: var(--spacing-xxs) 0;
}

.metricSubtext {
  font-size: var(--font-size-sm);
  color: var(--color-gray-500);
}

/* Visualizations container */
.visualizationsContainer {
  grid-column: 2;
  grid-row: 1 / span 2;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: var(--spacing-lg);
}

/* Chart section */
.chartSection {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
  border: 1px solid var(--color-gray-1100);
  display: flex;
  flex-direction: column;
}

.chartHeader, .mapHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
  flex-wrap: wrap;
  gap: var(--spacing-sm);
}

.chartTitle, .mapTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin: 0;
}

.chartSubtitle, .mapSubtitle {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
}

.securityIcon, .globeIcon {
  color: var(--color-green-310);
}

.chartContainer {
  flex: 1;
  position: relative;
  min-height: 200px;
}

/* Map section */
.mapSection {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
  border: 1px solid var(--color-gray-1100);
  display: flex;
  flex-direction: column;
}

.mapContainer {
  flex: 1;
  position: relative;
  min-height: 200px;
  border-radius: var(--border-radius-md);
  overflow: hidden;
}

.mapLoading {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.loadingSpinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top-color: var(--color-primary-500);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Insights section */
.insightsContainer {
  grid-column: 1;
  grid-row: 2;
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-md);
}

.insightCard {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  border-left: 4px solid var(--color-primary-500);
}

.insightTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin: 0 0 var(--spacing-xs) 0;
}

.insightText {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  margin: 0;
  line-height: 1.5;
}

/* Conclusion section */
.conclusionCard {
  grid-column: 1 / span 2;
  grid-row: 3;
  background: linear-gradient(135deg, var(--color-gray-1100), var(--color-gray-1200));
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
  border: 1px solid var(--color-gray-1000);
}

.conclusionIcon {
  font-size: 2.5rem;
  color: var(--color-green-310);
  background: var(--color-gray-1100);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 2px solid var(--color-green-310);
}

.conclusionContent {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.conclusionTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin: 0 0 var(--spacing-sm) 0;
}

.conclusionText {
  font-size: var(--font-size-md);
  color: var(--color-gray-300);
  margin: 0;
  line-height: 1.6;
}

.dataCapabilities {
  margin-top: var(--spacing-md);
  padding-top: var(--spacing-md);
  border-top: 1px solid var(--color-gray-1000);
}

.dataCapabilitiesTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  color: var(--color-green-310);
  margin: 0 0 var(--spacing-xs) 0;
}

.dataCapabilitiesText {
  font-size: var(--font-size-sm);
  color: var(--color-gray-300);
  margin: 0;
  line-height: 1.5;
}

/* Map popup styling */
.mapPopup {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
  border: 1px solid var(--color-gray-1000);
  padding: 0;
}

.popupContent {
  padding: var(--spacing-xs) var(--spacing-sm);
  color: var(--text-on-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

/* Responsive styles */
@media (max-width: 1200px) {
  .content {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }
  
  .metricsContainer {
    grid-column: 1;
    grid-row: 1;
    grid-template-columns: repeat(2, 1fr);
  }
  
  .visualizationsContainer {
    grid-column: 1;
    grid-row: 2;
    grid-template-rows: auto auto;
  }
  
  .insightsContainer {
    grid-column: 1;
    grid-row: 3;
    grid-template-columns: repeat(3, 1fr);
  }
  
  .conclusionCard {
    grid-column: 1;
    grid-row: 4;
  }
  
  .title {
    font-size: var(--font-size-3xl);
  }
  
  .subtitle {
    font-size: var(--font-size-lg);
  }
}

@media (max-width: 992px) {
  .metricsContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .insightsContainer {
    grid-template-columns: 1fr;
  }
  
  .title {
    font-size: var(--font-size-2xl);
  }
  
  .subtitle {
    font-size: var(--font-size-md);
  }
  
  .conclusionCard {
    flex-direction: column;
    text-align: center;
    padding: var(--spacing-md);
  }
  
  .conclusionTitle {
    font-size: var(--font-size-lg);
  }
  
  .conclusionText {
    font-size: var(--font-size-base);
  }
  
  .dataCapabilitiesTitle {
    font-size: var(--font-size-base);
  }
  
  .dataCapabilitiesText {
    font-size: var(--font-size-xs);
  }
}

@media (max-width: 768px) {
  .metricsContainer {
    grid-template-columns: 1fr;
  }
  
  .chartContainer, .mapContainer {
    min-height: 200px;
  }
  
  .title {
    font-size: var(--font-size-xl);
  }
  
  .subtitle {
    font-size: var(--font-size-base);
    max-width: 100%;
  }
  
  .metricValue {
    font-size: var(--font-size-xl);
  }
  
  .metricLabel {
    font-size: var(--font-size-base);
  }
}

@media (max-width: 576px) {
  .container {
    padding: var(--spacing-sm);
  }
  
  .chartContainer, .mapContainer {
    min-height: 180px;
  }
  
  .metricCard {
    flex-direction: column;
    text-align: center;
    padding: var(--spacing-sm);
  }
  
  .conclusionCard {
    gap: var(--spacing-md);
  }
  
  .conclusionIcon {
    width: 60px;
    height: 60px;
    font-size: 1.8rem;
  }
}