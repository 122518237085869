/* File: frontend/src/pages/InvestorPitch/components/slides/InvestmentOpportunitySlide/InvestmentOpportunitySlide.module.css */

/* Updated InvestmentOpportunitySlide.module.css */

/* Overall container */
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-lg);
  width: 100%;
}

/* Main header section */
.header {
  text-align: center;
  margin-bottom: var(--spacing-md);
}

/* Title bigger than before */
.mainTitle {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  line-height: 1.1;
  background: linear-gradient(135deg, var(--color-primary), var(--color-blue-510));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.5;
}

/* Content container */
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Tabs styling */
.tabsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-gray-1000);
  margin-bottom: var(--spacing-md);
}

.tabButton {
  background: transparent;
  border: none;
  padding: var(--spacing-sm) var(--spacing-lg);
  font-size: var(--font-size-base);
  color: var(--color-gray-400);
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.tabButton:hover {
  color: var(--color-primary-500);
}

.activeTab {
  color: var(--color-primary-500);
  font-weight: var(--font-weight-semibold);
}

.activeTab::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-primary-500);
}

.tabContentContainer {
  flex: 1;
  overflow-y: auto;
  padding-right: var(--spacing-sm);
}

.tabContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

/* Strategy tab styles */
.strategyHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-gray-1100);
}

.strategyTitle {
  flex: 1;
}

.strategyTitle h3 {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-500);
  margin-bottom: var(--spacing-xs);
}

.strategyTitle p {
  font-size: var(--font-size-base);
  color: var(--color-gray-400);
}

.strategyHighlights {
  display: flex;
  gap: var(--spacing-md);
}

.highlightItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--color-gray-1100);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-sm) var(--spacing-md);
  min-width: 180px;
  border: 1px solid var(--color-gray-1000);
}

.highlightLabel {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  margin-bottom: var(--spacing-xxs);
}

.highlightValue {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-500);
}

/* Section styling */
.sectionTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.sectionIcon {
  color: var(--color-primary-500);
}

/* Markets section */
.marketsSection {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-gray-1100);
}

.marketsList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.marketItem {
  position: relative;
  padding-left: var(--spacing-lg);
  color: var(--color-gray-300);
  font-size: var(--font-size-base);
}

.marketItem::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--color-primary-500);
  font-weight: bold;
}

/* Allocation section */
.allocationSection {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-gray-1100);
}

.allocationChart {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.allocationItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.allocationBar {
  height: 24px;
  background: var(--color-gray-1100);
  border-radius: var(--border-radius-sm);
  overflow: hidden;
}

.allocationFill {
  height: 100%;
  background: linear-gradient(90deg, var(--color-primary-700), var(--color-primary-500));
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: var(--spacing-xs);
}

.allocationPercentage {
  color: white;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
}

.allocationDetails {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.allocationHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.allocationCategory {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--color-gray-300);
}

.allocationAmount {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-500);
}

.allocationDescription {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  margin: 0;
}

/* Business model section */
.businessModelSection {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-gray-1100);
}

.businessModelCard {
  background: var(--color-gray-1100);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  border: 1px solid var(--color-gray-1000);
}

.businessModelSubtitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--color-gray-300);
  margin-bottom: var(--spacing-md);
}

.businessModelDetails {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.businessModelItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.businessModelLabel {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
}

.businessModelValue {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--color-gray-200);
}

.businessModelAdvantage {
  margin-top: var(--spacing-md);
  padding-top: var(--spacing-md);
  border-top: 1px solid var(--color-gray-1000);
}

.advantageBar {
  height: 32px;
  background: var(--color-gray-1000);
  border-radius: var(--border-radius-sm);
  display: flex;
  overflow: hidden;
  margin-bottom: var(--spacing-sm);
}

.allegoryBar {
  background: var(--color-primary-600);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.industryBar {
  background: var(--color-gray-900);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.allegoryBar span, .industryBar span {
  font-size: var(--font-size-sm);
  color: white;
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
}

.advantageText {
  font-size: var(--font-size-sm);
  color: var(--color-gray-300);
  margin: 0;
  text-align: center;
}

/* Parallel capital section */
.parallelCapitalSection {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-gray-1100);
}

.parallelCapitalGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.parallelCapitalCard {
  background: var(--color-gray-1100);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  border: 1px solid var(--color-gray-1000);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.parallelCapitalHeader {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
  margin-bottom: var(--spacing-xs);
}

.parallelCapitalCategory {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--color-gray-300);
  margin: 0;
}

.parallelCapitalAmount {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-500);
}

.parallelCapitalDescription {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  margin: 0;
  line-height: 1.5;
}

.capitalStructureNote {
  background: var(--color-gray-1100);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  border: 1px solid var(--color-gray-1000);
  margin-top: var(--spacing-md);
}

.capitalStructureNote p {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  margin: 0;
  font-style: italic;
}

/* Timeline section */
.timelineSection {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-gray-1100);
}

.timelineContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.timelinePeriod {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.timelineHeader {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.timelineIndicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-primary-500);
}

.timelinePhase {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--color-gray-300);
  margin: 0;
}

.timelineMilestones {
  list-style: none;
  padding-left: calc(12px + var(--spacing-sm));
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  border-left: 1px dashed var(--color-gray-900);
  margin-left: 5px;
  padding-left: var(--spacing-lg);
}

.timelineMilestone {
  position: relative;
  color: var(--color-gray-400);
  font-size: var(--font-size-sm);
  padding-left: var(--spacing-md);
}

.timelineMilestone::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--color-primary-500);
}

/* Critical timing section */
.criticalTimingSection {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-gray-1100);
}

.criticalTimingCard {
  background: var(--color-gray-1100);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  border: 1px solid var(--color-gray-1000);
  border-left: 4px solid var(--color-primary-500);
}

.criticalTimingList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.criticalTimingList li {
  position: relative;
  padding-left: var(--spacing-lg);
  color: var(--color-gray-300);
  font-size: var(--font-size-sm);
  line-height: 1.5;
}

.criticalTimingList li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--color-primary-500);
  font-weight: bold;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .strategyHeader {
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .strategyHighlights {
    width: 100%;
    justify-content: space-between;
  }
  
  .businessModelDetails {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .parallelCapitalGrid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 992px) {
  .mainTitle {
    font-size: var(--font-size-3xl);
  }
  
  .subtitle {
    font-size: var(--font-size-lg);
    max-width: 90%;
  }
  
  .strategyHighlights {
    flex-wrap: wrap;
    gap: var(--spacing-sm);
  }
  
  .highlightItem {
    flex: 1;
    min-width: 100px;
  }
  
  .tabButton {
    padding: var(--spacing-xs) var(--spacing-md);
  }
}

@media (max-width: 768px) {
  .mainTitle {
    font-size: var(--font-size-2xl);
  }
  
  .subtitle {
    font-size: var(--font-size-md);
    max-width: 100%;
  }
  
  .businessModelDetails {
    grid-template-columns: 1fr;
  }
  
  .allocationHeader {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xxs);
  }
}

@media (max-width: 576px) {
  .container {
    padding: var(--spacing-md);
  }
  
  .mainTitle {
    font-size: var(--font-size-xl);
  }
  
  .subtitle {
    font-size: var(--font-size-base);
  }
  
  .strategyTitle h3 {
    font-size: var(--font-size-xl);
  }
  
  .strategyTitle p {
    font-size: var(--font-size-sm);
  }
  
  .highlightValue {
    font-size: var(--font-size-base);
  }
  
  .sectionTitle {
    font-size: var(--font-size-base);
  }
  
  .tabButton {
    font-size: var(--font-size-sm);
    padding: var(--spacing-xs) var(--spacing-sm);
  }
}