/* File: frontend/src/pages/SuperAdmin/SuperAdmin.module.css */

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--bg-secondary);
  overflow-x: hidden; /* Prevent horizontal overflow */
  max-width: 100vw; /* Ensure container doesn't exceed viewport width */
}

.content {
  display: flex;
  flex: 1;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.mainContent {
  flex: 1;
  padding: var(--spacing-lg);
  overflow-y: auto;
  overflow-x: hidden; /* Prevent horizontal overflow */
  max-width: calc(100% - 260px); /* Account for sidebar width */
}

.sectionContent {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-card);
  padding: var(--spacing-lg);
  margin-top: var(--spacing-md);
  overflow-x: hidden; /* Prevent horizontal overflow */
}

@media (max-width: 1024px) {
  .mainContent {
    padding: var(--spacing-md);
    max-width: calc(100% - 260px);
  }
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }
  
  .mainContent {
    padding: var(--spacing-md);
    max-width: 100%; /* Full width on mobile */
  }
  
  .sectionContent {
    padding: var(--spacing-md);
  }
}

@media (max-width: 480px) {
  .mainContent {
    padding: var(--spacing-sm);
  }
  
  .sectionContent {
    padding: var(--spacing-sm);
  }
}