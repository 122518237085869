/* File: frontend/src/pages/InvestorPitch/components/slides/HistoricalInventionSlide/HistoricalInventionSlide.module.css */

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-md);
  width: 100%;
  overflow: hidden;
  position: relative;
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-lg);
  z-index: 2;
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  background: linear-gradient(135deg, var(--color-blue-310), var(--color-primary-500));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 800px;
  margin: 0 auto;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  gap: var(--spacing-xl);
  flex: 1;
  z-index: 2;
}

/* Timeline styles */
.timelineContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.timelineItem {
  display: flex;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
}

.timelineYear {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-500);
  min-width: 80px;
  text-align: center;
}

.timelineContent {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  border-left: 3px solid var(--color-primary-500);
}

.timelineContent h3 {
  font-size: var(--font-size-lg);
  margin-bottom: var(--spacing-xs);
  color: var(--text-on-primary);
}

.timelineContent p {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  line-height: 1.5;
}

.timelineDivider {
  display: flex;
  align-items: center;
  margin: var(--spacing-md) 0;
  padding-left: 40px;
}

.timelineLine {
  height: 2px;
  background: var(--color-gray-900);
  flex: 1;
}

.timelineDot {
  width: 12px;
  height: 12px;
  background: var(--color-primary-500);
  border-radius: 50%;
  margin: 0 var(--spacing-sm);
}

/* AI Visualization styles */
.aiVisualization {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.canvasContainer {
  width: 100%;
  height: 300px;
  position: relative;
  margin-bottom: var(--spacing-lg);
}

.animationCanvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.aiLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 6rem;
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-500);
  text-shadow: 0 0 20px rgba(0, 149, 255, 0.5);
  letter-spacing: 0.1em;
  z-index: 1;
}

.inventorNote {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  width: 100%;
  border: 1px solid var(--color-gray-1100);
}

.inventorHeader {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.inventorAvatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--color-primary-500);
}

.inventorAvatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inventorInfo h3 {
  font-size: var(--font-size-lg);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-xxs);
}

.inventorInfo p {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
}

.inventorNote blockquote {
  font-size: var(--font-size-md);
  color: var(--color-gray-300);
  line-height: 1.6;
  font-style: italic;
  position: relative;
  padding-left: var(--spacing-md);
  border-left: 3px solid var(--color-primary-500);
}

/* Capabilities styles */
.capabilitiesContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-md);
  align-content: center;
}

.capabilityCard {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  border: 1px solid var(--color-gray-1100);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.capabilityCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-color: var(--color-primary-500);
}

.capabilityIcon {
  font-size: 1.5rem;
  margin-bottom: var(--spacing-xs);
}

.capabilityCard h3 {
  font-size: var(--font-size-md);
  color: var(--color-primary-500);
  margin-bottom: var(--spacing-xs);
}

.capabilityCard p {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  line-height: 1.5;
}

@media (max-width: 1200px) {
  .content {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
  
  .timelineContainer {
    grid-column: 1;
    grid-row: 1;
  }
  
  .aiVisualization {
    grid-column: 2;
    grid-row: 1;
  }
  
  .capabilitiesContainer {
    grid-column: 1 / span 2;
    grid-row: 2;
    grid-template-columns: repeat(4, 1fr);
  }
  
  .title {
    font-size: var(--font-size-3xl);
  }
  
  .subtitle {
    font-size: var(--font-size-lg);
  }
  
  .aiLabel {
    font-size: 5rem;
  }
}

@media (max-width: 992px) {
  .capabilitiesContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .title {
    font-size: var(--font-size-2xl);
  }
  
  .subtitle {
    font-size: var(--font-size-md);
  }
  
  .aiLabel {
    font-size: 4.5rem;
  }
}

@media (max-width: 768px) {
  .content {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    gap: var(--spacing-lg);
  }
  
  .timelineContainer {
    grid-column: 1;
    grid-row: 1;
  }
  
  .aiVisualization {
    grid-column: 1;
    grid-row: 2;
  }
  
  .capabilitiesContainer {
    grid-column: 1;
    grid-row: 3;
  }
  
  .title {
    font-size: var(--font-size-xl);
  }
  
  .subtitle {
    font-size: var(--font-size-base);
    max-width: 100%;
  }
  
  .aiLabel {
    font-size: 4rem;
  }
  
  .canvasContainer {
    height: 250px;
  }
}

@media (max-width: 576px) {
  .capabilitiesContainer {
    grid-template-columns: 1fr;
  }
  
  .timelineItem {
    flex-direction: column;
    gap: var(--spacing-xs);
  }
  
  .timelineYear {
    min-width: auto;
    text-align: left;
  }
  
  .timelineDivider {
    padding-left: 0;
  }
  
  .inventorHeader {
    flex-direction: column;
    text-align: center;
  }
  
  .canvasContainer {
    height: 200px;
  }
  
  .aiLabel {
    font-size: 4.5rem;
  }
}